<template>
  <div class="classroom-door-lock-manage">
    <div class="header">
      <div class="search">
        <van-dropdown-menu>
          <van-dropdown-item class="menu-item" v-model="searchType" :options="searchTypeOptions" />
        </van-dropdown-menu>
        <van-search class="search-content" v-model.trim="searchContent" @search="onSearch" placeholder="请输入搜索关键词" />
        <div class="search-btn" @click="onSearch">搜索</div>
      </div>
    </div>
    <div class="main">
      <van-list :immediate-check="false" v-model="listStatus.loading" :finished="listStatus.finished" @load="onLoad">
        <div class="list-item" v-for="item in list" :key="item.id">
          <item-card :title="item.name">
            <div class="content">
              <div class="title">门锁列表</div>
              <div class="lock-list">
                <div class="lock-list-item" v-for="lock in item.lockList" :key="lock.id">
                  <i class="iconfont icon-mensuo iconStyle"></i>
                  <span class="lock-name">{{ lock.alias }}</span>
                  <i class="iconfont icon-dianchi iconStyle"></i>
                  <span class="power">{{ lock.power || 0 }}%</span>
                </div>
              </div>
              <div class="none-data" v-if="!item.lockList.length">暂无数据</div>
            </div>
            <template #footer>
              <div class="action">
                <van-button class="action-btn" type="info" size="small" round @click="doorLockManageClick(item)">门锁管理</van-button>
              </div>
            </template>
          </item-card>
        </div>
        <template #finished>
          <div v-if="list.length">没有更多了</div>
        </template>
      </van-list>
    </div>
  </div>
</template>

<script>
import "@/assets/less/views/classroom/classroomDoorLockManage.less";
import { SUCCESS_CODE } from "@/apis/notification";
import Filter from "ldap-filters";

export default {
  name: "ClassroomDoorLockManage",
  data() {
    return {
      searchType: "classroomName",
      searchContent: "",
      searchTypeOptions: [{ text: "课室名称", value: "classroomName" }],

      list: [],
      listStatus: {
        loading: true,
        finished: false
      },

      pager: {
        pageNumber: 0,
        pageSize: 10
      }
    };
  },
  methods: {
    getClassroomLockIdsApi(query = null) {
      let params = {
        query,
        pageNumber: this.pager.pageNumber,
        pageSize: this.pager.pageSize
      };
      return this.$api.getClassroomLockIds({
        params
      });
    },
    getLockListApi(lockIds) {
      let params = {
        requestSource: "NB",
        lockIds: lockIds.toString()
      };
      return this.$api.getLockList({
        params
      });
    },
    async getClassroomLockList(lockIds) {
      let ret = await this.getLockListApi(lockIds);
      if (ret.code != SUCCESS_CODE) return [];
      return ret.data;
    },
    async getClassroomList(sign = false) {
      let query = [];
      if (this.searchContent) query.push({ key: this.searchType, value: this.searchContent, oper: "equalTo" });
      query = this.getQuery(query);
      let ret = await this.getClassroomLockIdsApi(query);
      if (ret.code != SUCCESS_CODE) return;
      let data = ret.data;
      data.forEach(classroom => (classroom.lockList = []));
      let lockIds = data.map(item => item.locks.map(item => item.lockId)).flat(1);
      let lockList = await this.getClassroomLockList(lockIds);
      lockList.forEach(lock => {
        let lockId = lock.id;
        let classroom = data.find(classroom => classroom.locks.map(item => item.lockId).includes(lockId));
        if (classroom) {
          classroom.lockList.push(lock);
        }
      });
      if (sign) {
        this.list = this.list.concat(data);
      } else {
        this.list = data;
      }
      this.listStatus.loading = false;
      this.handlePagination(ret.totalPages);
    },
    handlePagination(totalPages) {
      if (this.pager.pageNumber + 1 < totalPages) {
        // 有更多数据
        this.pager.pageNumber += 1;
      } else {
        this.listStatus.finished = true;
      }
    },
    getQuery(query) {
      // (&(xxx=xxx)(xxx=xxx))
      // [{key:xxx,value:xxx,oper:xxx}]
      if (query == [] || query == null) return null;
      let filters = query.reduce((prev, item) => {
        let filter = Filter.attribute(item.key)[item.oper](item.value);
        prev.push(filter);
        return prev;
      }, []);
      if (filters.length == 1) {
        return filters[0].toString();
      } else if (filters.length > 1) {
        return Filter.AND(filters).toString();
      }
    },
    onSearch() {
      this.getClassroomList(false);
    },
    onLoad() {
      this.getClassroomList(true);
    },
    doorLockManageClick(classroom) {
      let { id, locks } = classroom;
      locks = JSON.stringify(locks);
      sessionStorage.setItem("locks", locks);
      this.$router.push({
        name: "doorLockManage",
        query: {
          classroomId: id
        }
      });
    },
    init() {
      this.getClassroomList();
    }
  },
  created() {
    this.init();
  }
};
</script>
